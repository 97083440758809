.modal-segments {

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;

    margin-top: 0;
    margin-bottom: 0;
  }

  &__button-block {
    display: flex;
    border-top: 1px solid #E8E8E8;
    padding-top: 16px;
    margin-top: 32px;
  }

  &__button-block .ant-form-item-control {
    width: auto !important;
    margin-left: auto;
    flex-grow: 0;
  }

  &__stage-item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__date {
    margin-left: 8px;
    margin-right: 8px;
    z-index: 1001;
  }

  &__select {
    max-width: 280px;
    width: 280px !important;
    margin-left: 8px;
    margin-right: 8px;
  }

  &__delete-icon {
    font-size: 20px;
    color: #C4C4C4;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s linear;

    &:hover {
      color: #000;
    }
  }

  &__add-stage {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #03B94E;

    cursor: pointer;
  }
}

.modal-segments__stage-item .ant-form-item-explain-error {
  margin-left: 8px;
  margin-top: 42px;
  position: absolute;
}

.modal-segments__stage-item .ant-form-item {
  margin-bottom: 0;
}
