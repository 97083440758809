.customWrapper {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  margin: 12px 0;
  gap: 8px;

  label {
    margin-bottom: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    color: #000;
    margin: 0;
    min-width: 22px;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
    padding: 8px 10px;
    max-width: 690px;
    background-color: #ffffff;
  }
}

