.promocodes-activations-modal {
  .ant-modal-header {
    text-align: center;
    div {
      font-size: 16px;
      font-family: Roboto !important;
      font-weight: bold;
    }
  }

  .ant-modal-close-x {
    opacity: 0.6;
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  &__body {
    width: 100%;
    height: 410px;
    overflow-x: hidden;
  }

  &__activations-wrapper {
    height: 71px;
    * {
      display: flex;
      align-items: center;
    }
    &__odd-elem {
      background-color: #fafafa;
    }

    span {
      width: 6%;
      height: 100%;
      margin: 0 5px;
    }
  }

  &__activations {
    width: 75%;
    height: 100%;
    font-family: Roboto;
    font-weight: bold;
    overflow-wrap: anywhere;
  }

  &__activations-count {
    width: 19%;
    @media (max-width: 525px) {
      padding-left: 10px;
    }
  }
}
