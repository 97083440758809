.search-users {
  &__autocomplete {
    min-height: 40px;
    width: 100%;
    margin-bottom: 16px;

    border: 1px solid #c4c4c4;
    border-radius: 4px;

    & .ant-input-affix-wrapper {
      background-color: transparent;
    }

    & .certain-category-icon {
      color: #c4c4c4;
    }

    & .ant-select-selection-placeholder {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: #c4c4c4;

      margin-top: 5px;
      opacity: 1;
      transition: 0.3s linear;
    }

    &.ant-select {
      cursor: default !important;
    }
  }

  &__option {
    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #f5f5f5;
    }

    &-info {
      display: flex;
    }

    &-name {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 6px;
    }

    &-item {
      display: flex;
      align-items: center;

      &-icon {
        font-size: 18px;
        margin-right: 6px;
        color: #c4c4c4;
      }

      &-text {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: #000000;
        margin-right: 26px;
      }
    }
  }
}
