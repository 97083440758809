.ModalDeleteWarning {
  &__content {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;
  }
  &__icon {
    font-size: 24px;
    color: #ff4747;
    margin-right: 8px;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
  }
  &__button-delete {
    height: 40px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #ff4747 !important;
    text-transform: uppercase;

    background-color: #ebedee !important;
    border: 1px solid #ebedee !important;
    border-radius: 4px !important;
    box-sizing: border-box;
    box-shadow: none;
    margin-right: 16px;
    padding: 9px 16px;

    &:hover,
    &:focus {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #ff4747 !important;
    }

    &:active {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #ff4747 !important;
    }
  }

  &__button-cancel {
    height: 40px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #5c5c5c !important;
    text-transform: uppercase;

    background-color: #fff !important;
    border: 2px solid #ebedee !important;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;

    padding: 9px 16px;

    &:hover,
    &:focus {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 2px solid #fff !important;
    }

    &:active {
      color: #ff4747;
      background-color: #ebedee !important;
      border: 2px solid #fff !important;
    }
  }
}
