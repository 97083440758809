.modal {
  width: 522px;

  h1 {
    font-size: 16px;
    font-weight: 500;
  }

  label {
    font-weight: normal;
  }

  .select {
    width: 100%;
    font-size: 16px;
    margin-bottom: 11px;
    :global(.ant-select-selection__placeholder) {
      color: black;
    }
  }

  .footer {
    display: flex;
    margin-top: 14px;
    justify-content: flex-end;
    button:first-of-type {
      margin-right: 11px;
    }
  }

  .okButton {
    background-color: #ebedee;
    color: #ff4747;
    border: 1px solid #ebedee;
    &:disabled {
      opacity: 50%;
    }
    &:hover {
      border: 1px solid #ff4747;
    }
  }
}
