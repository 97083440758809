.discountsModal {
  :global {
    .ant-drawer-close {
      left: 0;
    }

    .ant-drawer-title {
      width: 100%;
      padding: 0 24px 0 60px;
    }
  }
}

.discountsForm {
  min-height: 100%;
  background-color: var(--admin-theme_Grey_00);
  border-radius: 8px;

  :global {
    .ant-form-item {
      padding: 0 !important;
      @media (max-width: 570px) {
        margin-bottom: 5px;
      }
    }
  }
}

.modalTitleBlock {
  min-height: 68px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--system_white);

  padding-left: 32px;
  padding-right: 24px;

  @media (max-width: 570px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  button {
    box-shadow: none !important;
  }
}

.modalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--admin-theme_Black);
  margin-left: 34px;
  margin-top: 10px;
  @media (max-width: 570px) {
    margin: 0;
  }
}

.modalSaveButton {
  min-width: 164px;
  margin-left: auto;
  @media (max-width: 570px) {
    margin: 0;
    margin-bottom: 10px;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
}

.modalCloseIcon {
  font-size: 28px;
  color: var(--admin-theme_Grey_02);

  transform: rotate(45deg);
  transition: 0.3s linear;

  &:hover {
    color: var(--admin-theme_Black);
  }
  @media (max-width: 570px) {
    margin-top: 10px;
  }
}
