.uploadPhotoItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.avatarBlock {
  min-width: 200px;
  min-height: 256px;
}
.avatar {
  max-width: 200px;
}
.uploadButtonBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}
.uploadButton {
  color: var(--admin-theme_Grey_04) !important;
  background: transparent !important;
  border: 1px solid var(--admin-theme_Grey_02) !important;
  border-radius: 4px;
}
.uploadPrompt {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--admin-theme_Grey_04);

  margin-top: 2px;
}
