.AccessZones {
  height: 100%;
  li {
    height: 60px !important;
  }
  &__content {
    // If the "Stadiums" tabs are enabled, then you need to remove the top space
    height: calc(100% - 90px);
    margin: 24px;
    background-color: #fff;
    display: flex;
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;
  }

  &__addButton {
    border: 2px solid #ebedee !important;
    border-radius: 4px !important;
    padding: 0px 16px 0 20px;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    color: #1890ff !important;
    span:last-child {
      color: #1890ff !important;
    }
    &:hover {
      border-color: #1890ff !important;
      color: #1890ff !important;
    }
  }

  &__addButton-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__addButton-icon {
    color: #c4c4c4 !important;
    font-size: 20px;
  }

  &__title {
    margin: 0;

    font-size: 36px;
    line-height: 32px;
    box-sizing: border-box;
    letter-spacing: 0.5px;
  }

  &__left-container {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 382px;
    width: 100%;
    overflow-y: auto;
    color: #000;
    z-index: 1;
    .ant-menu-item-selected:hover {
      color: #000 !important;
      background-color: #ebedee !important;
    }
  }

  &__button-delete {
    width: 139px;
    min-height: 40px;
    background-color: #ebedee !important;
    border: 1px solid #ebedee !important;

    box-shadow: none;
    color: #ff4747 !important;
    span {
      color: #ff4747 !important;
    }

    &:hover {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }
  }

  &__button {
    width: 202px;
    min-height: 40px;
    background-color: #1890ff !important;
    border: 1px solid #1890ff !important;
    box-shadow: none;

    &:hover {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &_hidden {
      display: none;
    }
  }

  &__right-container-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__right-container-title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    line-height: 100.7%;
    margin: 0 0 24px;
  }

  &__right-container-subtitle {
    margin: 0 0 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #000;
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px 0 0 32px;
    width: 100%;
    color: #000;
    z-index: 2;
  }
}

.ant-tabs-tab {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}
