.route-content_loyalty {
  &-modal-hover-name {
    max-width: 468px;

    position: absolute;
    top: 20px;
    left: 58px;

    background-color: #fff;
    border-radius: 4px;
    padding: 16px;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;

    visibility: hidden;
    transition-delay: 0s;

    &:hover {
      visibility: visible;
    }
  }

  &-modal-image {
    width: 210px;
  }
}

.route-content_loyalty-examples {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.example-profile-tickets {
  width: 210px;

  &__image {
    image-rendering: -webkit-optimize-contrast;
  }
}

.example-payment {
  width: 210px;

  &__image {
    image-rendering: -webkit-optimize-contrast;
  }
}