.modalItemsBlock {
  background-color: var(--system_white);
  margin: 24px 24px 44px 24px;
  padding: 24px 24px 44px 32px;
  border-radius: 8px;
}

.title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--system_black);

  margin-bottom: 16px;
  margin-top: 42px;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  max-width: 775px;
}

.radioGroup {
  width: 100%;
  display: flex;
  align-items: center;
}

.infoIcon {
  font-size: 24px;
  color: var(--admin-theme_Grey_02);
  margin-left: -8px;
  margin-right: 32px;
  cursor: help;
}

.popover {
  max-width: 403px;
  background-color: var(--system_white);
  border-radius: 8px;
  padding: 4px 0 8px 0;
}
.popoverText {
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: var(--system_black);
}
.popoverImgBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
}
.popoverImg {
  max-width: 191px;
  image-rendering: -webkit-optimize-contrast;
}

.input {
  width: 100%;
  border: 1px solid var(--admin-theme_Grey_02);
  border-radius: 4px;
}

.prompt {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: var(--admin-theme_Grey_03);
}
.promptUnderTitle {
  margin-top: -10px;
  margin-bottom: 10px;
}
.promptUnderInput {
  margin-top: 8px;
  margin-bottom: 0;
}

.discountInput {
  width: 138px;
  min-height: 40px;
  margin-right: 8px;
}

.numberInput {
  width: 74px;
  min-height: 40px;
  margin-right: 8px;
}

.numberInput[type=number], .discountInput[type=number] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover, &:focus {
    -moz-appearance: number-input;
  }
}

.discountSelect {
  width: 76px !important;
  min-height: 40px;
  margin-right: 56px;
}

.discountText {
  font-size: 16px;
  line-height: 20px;
  color: var(--admin-theme_Grey_03);
}

.maxUseCountsPerUserInput {
  width: 73px;
  margin-top: 4px;
}

.periodSelect {
  width: 153px !important;
  min-height: 40px;
}

.inlineInputItem {
  display: inline-block !important;
  margin-right: 16px;
}

.periodOption {
  text-transform: uppercase;
  color: var(--admin-theme_Grey_03);
}
