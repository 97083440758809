.segments__segment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px 0 32px;

  &-placeholder-wrapper {
    // Style for displaying a placeholder in the center of the screen, not a block. Actual on small screens
    // Screen height minus headers and padding to block with placeholder
    height: calc(100vh - 166px);
  }

  &-form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #000;

    margin-top: 0;
    margin-bottom: 32px;
  }

  &-description-block {
    margin-top: -26px;
    margin-bottom: 36px;
    margin-left: 2px;
    display: flex;
    align-items: center;
  }

  &-type-icon {
    margin-right: 8px;

    &_static {
      width: 13px;
    }
    &_dynamic {
      width: 19px;
    }
  }

  &-type-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 4px;
    color: rgba(50, 50, 50, 0.5);

    margin: 0;
  }

  &-input-title {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    margin-top: 0;
    margin-bottom: 12px;
  }

  &-input {
    width: 100%;
    margin-bottom: 16px !important;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    &::placeholder {
      color: #c4c4c4;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &:focus::placeholder {
      opacity: 0;
    }

    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: #8e8e8e;
    }
  }

  &-textarea {
    width: 100%;
    min-height: 40px !important;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    padding-top: 6px;
    padding-bottom: 6px;

    &::placeholder {
      color: #c4c4c4;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &:focus::placeholder {
      opacity: 0;
    }
  }

  &-radios-wrapper {
    position: relative;
    width: fit-content;
  }
  &-radios {
    margin-top: 19px;
    margin-bottom: 19px;
  }
  &-radio {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-loading-phones-icon {
    position: absolute;
    top: 23px;
    right: -23px;
  }

  &-table {
  }
  &-table-column {
    background-color: transparent !important;

    &_name {
    }
    &_phone {
      white-space: nowrap;
    }
  }
  &-user-item-icon {
    font-size: 16px;
    color: #c4c4c4;

    transform: rotate(45deg);
    transition: 0.3s linear;

    &:hover {
      color: #ff0000;
    }
  }
}

.ant-input {
  &::placeholder {
    color: #c4c4c4;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &:focus::placeholder {
    opacity: 0;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
