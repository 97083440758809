.texteditor {
  position: relative;
  margin-top: 20px;

  :global(.language-markdown) {
    width: 100%;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
  }

  :global(.wmde-markdown-color) {
    line-height: 1.5;
    height: 100%;
  }

  :global(.wmde-markdown-color code[class*='language-']) {
    max-width: 100%;
    color: #000;
  }

  :global(.w-md-editor-toolbar li > button) {
    height: 25px;

    &:hover,
    &:focus {
      background-color: rgb(55, 24, 84);
      color: #fff;
    }
  }

  :global(.w-md-editor-toolbar li > button > svg) {
    width: 16px;
    height: 16px;
  }

  :global(.w-md-editor-text) {
    //height: 100%;
  }

  :global(.w-md-editor-input) {
    //height: 100% !important;
  }

  :global(.w-md-editor) {
    min-height: 200px;
    //height: 100% !important;
    padding-bottom: 10px;
  }

  :global(.w-md-editor-content) {
    height: 100% !important;
  }

  textarea {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;

    &::selection {
      background: rgba(55, 24, 84, 0.3);
    }
  }

  & .texteditor__counter {
    position: absolute;
    top: -30px;
    right: 0;
    font-size: 14px;

    @media (max-width: 570px) {
      top: -40px;
    }
  }
}
