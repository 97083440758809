.AccessZoneForm {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  overflow: auto;
  flex: 1;

  &__content {
    width: 100%;
  }

  &__inputs {
    padding-right: 32px;
  }

  &__input {
    margin: 0;
    color: #000;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }
  &__textarea {
    padding-top: 5px;
    margin: 0;
    font-size: 16px;
    color: #000;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    margin: 0 0 12px;
    color: #000;
  }

  &__checkbox {
    margin: 0 0 24px;
    border-color: #1890ff !important;
    font-weight: normal;
    letter-spacing: 0.25px;
    color: #000;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
  }

  &__button-delete {
    width: 139px;
    min-height: 40px;
    background-color: #ebedee !important;
    border: 1px solid #ebedee !important;

    box-shadow: none;
    color: #ff4747 !important;
    span {
      color: #ff4747 !important;
    }

    &:hover {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }

    &_hidden {
      display: none;
    }
  }

  &__button {
    width: 202px;
    min-height: 40px;
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 22px;
    color: #fff !important;
    text-align: center;
    text-transform: uppercase;
    background-color: #1890ff !important;
    border: 1px solid #1890ff !important;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;

    &:hover {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  &__footer {
    max-width: 100%;
    width: 100%;
    padding: 24px 32px 24px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    &_create {
      justify-content: flex-end;
    }
  }
}
