.route-content_loyalty {
  &-modal-hover-legality-documentation {
    max-width: 462px;

    position: absolute;
    top: 10px;
    left: 64px;

    background-color: #fff;
    border-radius: 4px;
    padding: 16px;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;

    visibility: hidden;
    transition-delay: 0s;

    &:hover {
      visibility: visible;
    }
  }
}