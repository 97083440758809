.column-array {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 910px) {
  .block-column {
    font-size: 10px !important;
  }
}

@media (max-width: 560px) {
  .block-column {
    font-size: 6px !important;
  }
}
