.select {
  width: 230px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  text-transform: uppercase;

  @media screen and (max-width: 475px) {
    width: 100% !important;
  }
}

.option {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  border-bottom: 1px solid #ebedee;
  height: fit-content;

  text-align: center;
}
