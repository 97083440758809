.container {
  display: flex;
  flex-direction: column;
}

.statusFooter {
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 940px) {
    flex-direction: column;
    height: auto;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: start;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: 40px;

    @media (max-width: 940px) {
      margin-right: 0;
      overflow-wrap: anywhere;
    }
    &__circle {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      margin-right: 12px;
    }
    &__title {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: #000000;
    }
    &__value {
      margin-left: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #000000;
    }
  }
}
