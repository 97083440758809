.menu__container {
  min-height: 56px;
  background-color: #FAFAFA;
  border-radius: 4px 4px 0px 0px;
  border-right: none;
  display: flex;
  box-sizing: border-box;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 900px) {
      display: block;
  }

  @media screen and (max-width: 576px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 20px;
    padding: 0;
    background-color: #fff;
  }
}

.dropdown__item {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.filter__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-right: 23px;
  margin-left: 23px;

  &_left {
    display: flex;

    *:not(:last-child) {
      margin-right: 8px;
    } 

    @media screen and (max-width: 450px) {
      flex-wrap: wrap;

      *:not(:last-child) {
        margin-bottom: 5px;
        margin-right: 0;
      } 
    } 

    @media screen and (max-width: 576px) {
      width: 100%;
    }   
  }

  @media screen and (max-width: 576px) {
    margin-right: 16px;  
    margin-left: 16px;
    margin-bottom: 8px;
  }   

  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }  
}

.menu__container {
  margin-right: 23px;
  margin-left: 23px;

  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 16px;
    min-height: auto;
  }
}

.label {
  margin: 0;
  display: flex;
  align-items: center;
  width: 230px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  .input {
      margin: 0;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      cursor: pointer;
  }

  @media screen and (max-width: 1530px) {
    width: 300px;
  }

  @media screen and (max-width: 1250px) {
    display: none;
  }
}

.list {
  padding: 0 23px;
  height: 55vh;
  overflow: auto;

  @media screen and (max-width: 1200px) {
    height: 100%;
  }

  @media screen and (max-width: 567px) {
    padding: 0 16px;
  }
}


.paginationFooter {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 18px 24px 24px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &__text {
    margin-left: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 24px;
  }  
}

.pagination {
  margin-left: auto;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }  
}

.paginationFooter__text {
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.checkbox {
  font-weight: 400;
  color: #000;
}

.filter__label {
  position: relative;
  margin-left: 17px;
  margin-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -12px;
    width: 8px;
    height: 1px;
    background-color: #000;
    transform: translateY(-50%) rotate(50deg);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    width: 8px;
    height: 1px;
    background-color: #000;
    transform: translateY(-50%) rotate(-50deg);
  }

  @media screen and (max-width: 576px) {
    margin-left: 10px;
  }  
}

.filter__input {
  width: 0;
  height: 0;
}

.filter__input:checked ~ .filter__label::after {
  transform: rotate(-50deg);
}

.filter__input:checked ~ .filter__label::before {
  transform: rotate(50deg);
}
