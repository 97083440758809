.AccessMenu {
  &__list {
    list-style: none;
    width: 100%;
    border: none;
  }
  &__item {
    display: flex;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    flex-shrink: 0;
    font-size: 16px;
    line-height: 20px;

    color: #000;

    &:last-of-type {
      border-bottom: none;
    }
    &_new {
      background-color: #ebedee;
    }
  }
}

// Such a solution, but I have not found a better one yet
.ant-menu.ant-menu-light .ant-menu-item-selected.AccessMenu__item {
  background-color: #ebedee;
}
.ant-menu.ant-menu-light .ant-menu-item-active.AccessMenu__item {
  color: #000;
}
