.AccessEntranceForm {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  overflow: auto;
  flex: 1;

  &__content {
    width: 100%;
  }
  &__inputs {
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__input {
    margin: 0 0 21px;
    color: #000;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__textarea-wrap {
    padding-right: 32px;
  }
  &__textarea {
    padding-top: 5px;
    font-size: 16px;
    color: #000;
    max-height: 300px;

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__subtitle-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    margin: 0 0 12px;
    color: #000;
    display: flex;
    align-items: center;
    z-index: 1;

    &_wrapped {
      margin: 0;
    }
  }

  &__subtitle-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    cursor: help;
  }

  &__popup {
    display: none;
    position: absolute;
    top: 27px;
    left: 20%;
    width: 462px;

    padding: 16px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.85);
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.5);
    z-index: 2;
    &_shown {
      display: flex;
    }
  }

  &__popup-text {
    margin: 0;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 32px;
  }

  &__input-wrap {
    max-width: 100%;
    width: 100%;
    margin-right: 30px;

    &:last-of-type {
      margin: 0;
    }
  }

  &__input {
    margin: 0;
  }

  &__selects {
    display: flex;
    margin: 20px 0 50px;
    padding-right: 32px;
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__select-wrap {
    max-width: 100%;
    width: 100%;
    margin-right: 85px;

    &:last-of-type {
      margin: 0;
    }
  }

  &__select {
    max-width: 100%;
    width: 100%;

    .ant-select-selection__placeholder {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      letter-spacing: 0.5px;
      text-transform: uppercase;

      color: #5c5c5c;
    }
  }

  &__select-option {
    background-color: #fff;
  }

  &__button-delete {
    width: 139px;
    min-height: 40px;
    background-color: #ebedee !important;
    border: 1px solid #ebedee !important;

    box-shadow: none;
    color: #ff4747 !important;
    span {
      color: #ff4747 !important;
    }
    &:hover {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #fff !important;
    }

    &_hidden {
      display: none;
    }
  }

  &__button {
    width: 202px;
    min-height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #fff !important;
    text-align: center;
    text-transform: uppercase;
    background-color: #1890ff;
    border: 1px solid #1890ff !important;
    border-radius: 4px !important;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;

    span {
      color: #fff !important;
    }

    &:hover {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &:focus {
      background-color: #1890ff !important;
      border: 1px solid #fff !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  &__footer {
    max-width: 100%;
    width: 100%;
    padding: 24px 32px 24px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    &_create {
      justify-content: flex-end;
    }
  }
}
