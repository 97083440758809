.name {
  color: #5c5c5c;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding-right: 8px;
  width: 178px;

  @media screen and (max-width: 780px) {
    min-width: 100%;
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
  }
}

.item {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;
}

.radio {
  width: 140px;
  margin-right: 13px;
}

.btnSelect {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 577px) {
    width: 100%;
  }
}

.menuItem {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  border-bottom: 1px solid #ebedee;
  height: 50px;
}
