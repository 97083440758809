.subtable-wrapper {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 12px;
}

.subtable-title {
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding-top: 8px;

  &__item {
    padding-left: 16px;
    padding-right: 16px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &__first-row {
    min-height: 32px;
    display: flex;
    align-items: flex-end;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;

    margin-top: 0;

    &_main {
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 0;
    }
    &_additional {
      color: #8e8e8e;
      margin-bottom: 8px;
    }
  }

  &__display-none {
    display: none;
  }
}

.subtable-title__badge .ant-badge-status-dot {
  width: 8px !important;
  height: 8px !important;
}

.ant-badge {
  width: 15px;
}

.ant-table-expanded-row > .ant-table-cell {
  background-color: #fff;
}

.ant-table-expanded-row > .ant-table-cell:hover {
  background-color: #fff;
}

.ant-table .ant-table-layout-fixed {
  background-color: #fafafa;
}
