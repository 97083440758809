@import './PromoModals/PromoCodesActivationsModal/style.scss';
@import './PromoModals/PromoCodesModal/style.scss';

.ant-select {
  min-width: 70px;
}

.promocode {
  .ant-table {
    padding: 20px 20px;
  }

  .ant-btn:not(.ant-btn-link) {
    font-size: 16px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #ffffff !important;
    padding-left: 36px;
    padding-right: 30px;
    background: #1890ff;
    border: none;

    span {
      color: #ffffff;
    }
  }

  .ant-btn-lg {
    span {
      color: #1890ff !important;
    }
  }

  .ant-card-body .ant-btn.ant-btn-link {
    color: #1890ff !important;
    width: 100%;
  }

  .ant-btn.ant-btn-default {
    background: none;
    border: 1px solid #d9d9d9;
    color: #595959;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
  }

  .ant-dropdown .ant-menu-item {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #595959;
  }

  .ant-table table {
    background: #f4f4f4;
  }

  .ant-table-thead tr th {
    text-align: left;
  }

  &__up-action-panel {
    h1 {
      justify-self: flex-start;
    }

    button {
      position: relative;
      top: 0;
      right: 20px;
      @media (max-width: 820px) {
        top: auto;
      }
    }
  }

  &__value {
    font-weight: 900;
  }

  &__activations-info {
    background-color: transparent !important;
    color: var(--clear-blue) !important;
    font-size: 14px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;

    span {
      font-size: 14px !important;
      color: var(--clear-blue) !important;
    }
  }

  &__item {
    padding: 60px 0 0 0;
    background-color: #fff;

    .ant-table-tbody > tr > td {
      background: white;

      .ant-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        @media (max-width: 1750px) {
          padding: 0;
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        @media (max-width: 1500px) {
          .anticon-down {
            display: none;
          }
          span {
            overflow-wrap: break-word;
          }
        }
        @media (max-width: 1250px) {
          span {
            font-size: 9px !important;
          }
        }
        @media (max-width: 1100px) {
          span {
            font-size: 12px !important;
          }
        }
        @media (max-width: 910px) {
          span {
            font-size: 9px !important;
          }
        }
        @media (max-width: 700px) {
          span {
            overflow-wrap: break-word;
            font-size: 7px !important;
          }
        }
        @media (max-width: 450px) {
          span {
            font-size: 5px !important;
          }
        }
      }
    }

    .ant-table-thead > tr.ant-table-expanded-row > th,
    .ant-table-tbody > tr.ant-table-expanded-row > td {
      background: #f4f4f4;
    }
  }

  tr.ant-table-expanded-row {
    td {
      background-color: #f4f4f4;
    }

    h5 {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85);
      padding: 3px 0;
      margin-right: 27px;
    }
  }

  &__sale {
    white-space: nowrap;
  }

  &__bound-item {
    padding: 3px 13px;
    background: white;
    border-radius: 15px;
    height: auto;
    margin-right: 8px;
    margin-bottom: 9px;
  }

  &__bound-kind {
    white-space: nowrap;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ccc;
    margin-right: 12px;
  }

  &__bound-value {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.85);
  }
}

.ant-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-card-body .ant-btn.ant-btn-link:not(.promo-add__bound-remove) {
    color: #1890ff;
    width: 100%;
  }

  .promo-add__bound-remove {
    border: 1px solid var(--default-border);
    font-size: 14px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:not(.ant-btn-link) {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #ffffff;
    padding-left: 36px;
    padding-right: 30px;
    background: #1890ff;
    border: none;
  }

  .ant-row {
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
    }

    .ant-row {
      display: block;
      padding: 0 12px;
      @media (max-width: 410px) {
        inline-size: min-content;
      }
    }

    .ant-col {
      margin-top: auto;
      @media (max-width: 1110px) {
        margin-top: 0;
        width: 153px;
        max-width: none;
      }
    }
  }

  .btn__select {
    margin-bottom: 28px;
    width: 100%;
    background-color: transparent !important;
    color: #1890ff !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px;
  }

  .ant-card-head-title {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.425);
  }

  .ant-card {
    margin-bottom: 24px;
    border-radius: 8px;
  }

  .promo-add__body {
    padding: 28px 143px;
    background: #f0f2f5;
    @media (max-width: 880px) {
      padding: 14px 14px;
    }
  }

  .promo-add__edit-codes-btn {
    width: 153px;
    height: 32px;
    background-color: transparent !important;
    color: var(--clear-blue) !important;
    border: solid 1px var(--default-border) !important;
    font-size: 14px !important;
    padding: 0 !important;
  }

  .promo-add__header {
    padding: 13px 16px 12px;
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
    @media (max-width: 530px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .ant-input {
      width: 270px;
      margin-right: auto;
      @media (max-width: 530px) {
        width: 100%;
      }
    }
  }

  .promo-add__activation-count {
    width: 77px;
    text-align: right;
  }

  .promo-add__promo-count {
    width: 108px;
  }

  .promo-add__bound-item {
    position: relative;
  }

  .promo-add__bound-remove {
    position: absolute;
    right: 5px;
    top: -10px !important;
    z-index: 1;
  }

  &-content-wrapper {
    @media (max-width: 1110px) {
      width: 100% !important;
    }
  }
}

.promo-modal {
  &__header {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }
}
