.expandedRowsWrapper {

  :global {
    .ant-table {
      background-color: var(--admin-theme_Grey_00);
    }

    & .ant-table-thead > tr > th {
      background-color: var(--admin-theme_Grey_00);
    }

    & .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: var(--admin-theme_Grey_02) !important;
    }

  }
}

.expandedRows {
  background-color: var(--admin-theme_Grey_00);
  border-radius: 10px;
  padding: 10px;
}
