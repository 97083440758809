.block {
  background-color: #ebedee;
  border-radius: 4px;
  padding: 3px 8px;
  width: auto;
  display: grid;
  grid-template: 1fr/auto auto;
  align-items: center;
  justify-content: space-between;
}

.title, .date {
}

.name {
  color: #5c5c5c;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding-right: 8px;
  width: 178px;
}

.item {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;
}
