.EmptyPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #000;

  &__title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__addButton {
    border: 2px solid #ebedee !important;
    border-radius: 4px !important;
    padding: 0px 16px 0 20px;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: #1890ff !important;
    span:last-child {
      color: #1890ff !important;
    }
    &:hover {
      border-color: #1890ff !important;
      color: #1890ff !important;
    }
  }

  &__addButton-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__addButton-icon {
    color: #c4c4c4 !important;
    font-size: 20px;
  }
}
