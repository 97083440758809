.discounts {
  &__header-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    @media (max-width: 400px) {
      flex-direction: column;
      align-items: center;
      button {
        margin-right: 0;
        margin-top: 10px;
        width: 70%;
      }
    }
  }

  &__header-title {
    margin-bottom: 0;
  }

  &__header-button {
    color: #1890ff !important;
    margin-right: 24px;

    span:last-child {
      color: #1890ff !important;
    }
  }

  &__header-button-icon {
    width: 16px;
    color: #c4c4c4 !important;
  }

  &__main-content {
    width: calc(100% - 48px);
    min-height: 100%;
    margin: 24px 24px 0 24px;
    background-color: #fff;
    border-radius: 4px;
  }

  &__search-input-wrapper {
    width: 100%;
    display: flex;
    margin-left: 0;
    margin-bottom: -28px;

    padding-top: 16px;
    padding-right: 16px;
  }

  &__search-input {
    width: 100%;
    min-height: 40px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;

    & .ant-input-affix-wrapper {
      background-color: transparent;
    }

    & .ant-input-group-addon {
      background-color: transparent;
    }

    & .ant-btn {
      background-color: transparent;
      border: none;
    }
  }

  &__tab-title {
    width: 187px;
    text-align: center;
    margin-bottom: -8px;
  }

  &__condition {
    background-color: #ebedee;
    border-radius: 30px;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 8px 16px;

    transition: 0.3s linear;
  }
}

.ant-table-row:hover .discounts__condition {
  background-color: #fff;
}
