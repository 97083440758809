.segments__menu {
  height: 100%;

  &-search-block {
    padding: 24px 20px;
  }

  &-search {

  }

  &-item {
    padding: 24px 20px 20px 20px !important;
    margin: 0 !important;
    height: auto !important;
    border-bottom: 1px solid #E8E8E8;

    &_new {
      background-color: #ebedee;
      padding: 25px 20px 26px 20px !important;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000;
      white-space: pre-line;

      margin-top: 0;
      margin-bottom: 0;
      padding-right: 6px;
    }
    &-header-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 8px;
    }
    &-count-block {
      min-width: fit-content;
      min-height: 24px;
      line-height: 0;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      padding: 0 8px 0 10px;
      background-color: #C4C4C4;
      border-radius: 8px;
    }
    &-count-icon {
      margin-right: 6px;

      &_static {
        width: 13px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      &_dynamic {
        width: 19px;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: -4px;
      }
    }
    &-count {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 3px;
      color: #fff;

      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
    }
    &-description {
      min-height: 32px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #8E8E8E;
      white-space: normal;

      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      word-wrap: break-word;

      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &-item.segments__menu-item_background-transparent.ant-menu-item-selected {
    background-color: #fff !important;
  }

  &-item.ant-menu-item-selected {
    background-color: #EBEDEE !important;
  }
}
