.route-content_loyalty {
  padding: 36px 23px 64px 56px;
  overflow: hidden;
  @media (max-width: 700px) {
    padding: 0px 23px 0px 0px;
  }

  &-deactivate-button {
    background: #ece9e0 !important;
    padding: 11px 16px;
    line-height: 16px;
    height: auto;

    span {
      color: var(--danger) !important;
    }

    &:hover {
      border-color: var(--danger) !important;

      span {
        color: var(--danger) !important;
      }
    }
  }

  &-button-active {
    background: #ece9e0 !important;

    span {
      color: var(--danger) !important;
    }

    &:hover {
      border-color: var(--primary) !important;

      span {
        color: var(--primary) !important;
      }
    }
  }

  &-input-wrapper {
    font-size: 14px;
  }

  &-legality-documentation-link-block {
    .anticon-paper-clip {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  &-cancel-button {
    box-shadow: none;

    &:hover {
      background-color: #ebedee !important;
      color: #5c5c5c !important;
    }
  }

  &-close-button {
    color: #ff4747 !important;

    &:hover {
      border-color: #ff4747 !important;
    }
  }

  &-title {
    max-width: 440px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 72px;
    color: #000;

    margin-top: 0;
    margin-bottom: 16px;

    position: relative;
    z-index: 5;
  }

  &-description {
    max-width: 544px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #000;

    margin-top: 0;
    margin-bottom: 48px;

    position: relative;
    z-index: 5;
  }

  &-subtitle {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    margin-top: 0;
    margin-bottom: 0;
    margin-right: 8px;

    position: relative;
    z-index: 5;

    display: inline-block;
  }

  &-list {
    list-style: none;

    margin-top: 16px;
    margin-bottom: 112px;
    padding-left: 24px;

    position: relative;
    z-index: 5;
  }

  &-list-item {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #000;

    position: relative;

    margin-top: 0;
    margin-bottom: 16px;

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;

      position: absolute;
      top: 6px;
      left: -24px;

      background-color: #c4c4c4;
    }
  }

  &-activate-button {
    height: 40px;
    min-width: 150px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 0;
    color: #fff;
    text-transform: uppercase;

    background-color: var(--primary);
    border: 1px solid transparent !important;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: unset;

    position: relative;
    z-index: 5;

    padding: 29px 24px;

    span {
      color: #ffffff !important;
    }
  }

  &-activate-description {
    max-width: 410px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8e8e8e;

    margin-top: 16px;
    margin-bottom: 0;

    position: relative;
    z-index: 5;
  }

  &-modal-title-block {
    display: flex;
    margin-bottom: 24px;
  }

  &-modal-title {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);

    margin-top: 0;
    margin-bottom: 0;
  }

  &-modal-text {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);

    margin-top: 0;
    margin-bottom: 0;
  }
}

.coin-image {
  position: absolute;
  z-index: 0;

  &_large {
    width: 540px;
    top: -11%;
    right: -3%;

    transform: rotate(-33deg);
  }

  &_medium {
    width: 150px;
    top: 74%;
    right: 19%;

    transform: rotate(77deg);
  }

  &_small {
    width: 100px;
    top: 52%;
    right: 10%;
  }
}
