.ac-dropdown {
  //width: 110%;
  padding: 8px 0 8px 16px;
  text-align: left;
  border: solid 1px rgba(0, 0, 0, 0.25);
  line-height: 18px;
  font-size: 16px;
  background: transparent;
  color: #5C5C5C;
  @media screen and (max-width: 900px) {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
  }
  &__item.active>a,
  &__item.active>a:focus,
  &__item.active>a:hover {
    background-color: var(--info);
  }
  @media screen and (max-width: 750px) {
    display: none;
    margin: 0;
  }
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #5C5C5C;
  background-color: #E6F7FF;
  border-color: #1890FF;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0rem rgba(72, 180, 97, 0.5);
  //box-shadow: none;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0rem rgba(72, 180, 97, 0.5);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 20rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 16px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
 .dropdown-toggle::after {
   display: inline-block;
   padding-right: 48px;
   padding-left: 10px;
   vertical-align: 0.255em;
   content: url("../../img/dropdownVector.svg");
   border-top: 0em solid;
   border-right: 0.3em solid transparent;
   border-bottom: 0;
   border-left: 0em solid transparent;
   transform: translateY(2px) rotate(180deg);
 }
.ac-dropdown:focus {
  color: #5C5C5C;
  background-color: white;
}
.ac-dropdown:hover {
  color: #5C5C5C;
  background-color: white;
}
.ac-dropdown:active {
  color: #5C5C5C;
  background-color: white;
}
.ac-dropdownMenu {
  display: flex;
  flex-direction: column;
}
.ac-dropdown--disabled {
  pointer-events: none;
  cursor: default;
  border-color: var(--lightGray);
}
.ac-dropdown--disabled .caret {
  opacity: .25;
}

.dropdown-actions {
  width: 174px;
}

.dropdown-status {
  width: 158px;
  margin-right: 28px;
}

.dropdown-categories {
  border-color: #dddddd;
  padding: 6px 12px;
}
