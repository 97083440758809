.AccessCategories {
  height: 100%;
  li {
    height: 60px !important;
  }
  &__content {
    // If the "Stadiums" tabs are enabled, then you need to remove the top indent
    height: calc(100% - 90px);
    margin: 24px;
    background-color: #fff;
    display: flex;
    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    margin: 0;
    padding: 19px 20px;
    font-size: 36px;
    line-height: 32px;
    box-sizing: border-box;
    background-color: #fff;
  }

  &__left-container {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 382px;
    width: 100%;
    overflow-y: auto;
    color: #000;
    z-index: 1;
    @media (max-width: 450px) {
      ul {
        display: flex;
        flex-direction: row;
        li {
          max-width: 150px !important;
        }
      }
    }
  }

  &__button {
    width: 202px;
    min-height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #fff !important;
    text-align: center;
    text-transform: uppercase;
    background-color: #1890ff;
    border: 1px solid #1890ff !important;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
    &:hover {
      background-color: #1890ff;
      border: 1px solid #fff !important;
    }
    &:focus {
      background-color: #1890ff;
      border: 1px solid #fff !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  &__right-container-content {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &-block {
      display: none;
    }
    @media (max-width: 450px) {
      height: 100vh;
      &-block {
        display: flex;
        justify-content: center;
        align-items: start;
        span {
          padding-top: 5px;
          padding-right: 20px;
        }
      }
    }
  }

  &__right-container-title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    line-height: 100.7%;
    margin: 0 0 20px;
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px 0 0 32px;
    width: 100%;
    z-index: 2;
  }

  &__select-list {
    max-width: 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 138px;
  }

  &__footer {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px 16px 0;
    box-sizing: border-box;
    background-color: #fff;
  }
}

.ant-tabs-tab {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}
