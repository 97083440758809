.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  margin: 0 -83px 22px;
  background-color: #ffffff;

  @media screen and (max-width: 800px) {
    margin: 0;
  }

  @media screen and (max-width: 576px) {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px;
    background-color: #ffffff;
    z-index: 9999;
  }
}

.group {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 576px) {
    align-items: center;
  }
}

.cancel {
  border: none;
  margin-right: 35px;
  padding: 0;
  width: 30px;
  height: 30px;
  color: #000000;
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;

  @media screen and (max-width: 576px) {
    margin-right: 12px;
    width: 18px;

    span {
      width: 18px;

      svg {
        width: 18px;
      }
    }
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin: 0;

  @media screen and (max-width: 576px) {
    font-size: 16px;
    font-weight: 700;
  }
}
