.route-content_loyalty {
  &-info-circle {
    font-size: 24px;
    color: #c4c4c4;
    vertical-align: -7px;
    cursor: help;
    position: relative;
  }

  &-info-circle_legality-documentation {
    &:hover + .route-content_loyalty-modal-hover-legality-documentation {
      transition: visibility;
      transition-delay: 0.5s;
      visibility: visible;
    }
  }

  &-info-circle_name {
    &:hover + .route-content_loyalty-modal-hover-name {
      transition: visibility;
      transition-delay: 0.5s;
      visibility: visible;
    }
  }

  &-loading-icon {
    position: absolute;
    top: 19px;
    right: 28px;

    &_percent {
      top: 25px;
      right: 20px;
    }

    &_select {
      top: 12px;
      right: 32px;
    }

    &_checkbox {
      position: relative;
      top: 0;
      right: 0;
      margin-right: 8px;
    }

    &_documentation {
      top: 13px;
      right: 0;
    }
  }

  &-modal-hover-text {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);

    margin-top: 0;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-legality-documentation-block {
    min-height: 60px !important;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 32px;
    padding-top: 16px;

    &_without-padding {
      padding-top: 0;
    }
  }

  &-legality-documentation-wrapper {
    min-height: 92px;
    position: relative;
  }

  &-legality-documentation-link-block {
    padding-top: 17px;
    padding-bottom: 34px;
  }

  &_hidden {
    display: none;
  }

  &-upload-button {
    height: 40px;
    background-color: #fff;
    margin-right: 16px;
    margin-bottom: 4px;

    &:hover,
    &:focus,
    &:active {
      background: #fff;
      color: #1890ff;
      border-color: #1890ff;
    }
  }

  &-input-link {
    width: 100%;

    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;

    margin-left: 16px;
    padding: 8px 12px;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &::placeholder {
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #c4c4c4;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &-input-link-wrapper {
    max-width: 358px;
    position: relative;
  }

  &-input-link-value {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #03b94e;
    margin-left: 8px;

    &:hover {
      color: #03b94e;
      text-decoration: underline !important;
    }
  }

  &-link-icon {
    font-size: 24px;
    color: #c4c4c4;
    vertical-align: -7px;
    margin-left: 16px;
    cursor: pointer;
  }

  &-input-wrapper {
    position: relative;
    display: inline-block;
  }

  &-text {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;

    margin-top: 26px;
    margin-bottom: 26px;

    &_margin-s {
      margin-top: 12px;
      margin-bottom: 0;
    }
  }

  &-input {
    width: 320px;

    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;

    margin-top: 8px;
    margin-bottom: 19px;
    margin-right: 16px;

    padding: 8px 12px;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &::placeholder {
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #c4c4c4;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &_margins-mod {
      margin-bottom: 0;
      margin-left: 29px;
      @media (max-width: 700px) {
        margin-left: 0px;
      }
    }
  }

  &-input-percent {
    width: 73px;

    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;

    margin-top: 14px;
    margin-bottom: 11px;
    margin-right: 8px;

    padding: 8px 12px;

    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &::placeholder {
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #c4c4c4;

      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &-select {
    width: 250px !important;
    max-width: 250px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #5c5c5c;
    text-transform: uppercase;

    box-sizing: border-box;
    border-radius: 4px;

    cursor: pointer;

    &::placeholder {
      text-transform: uppercase;
    }
  }
  &-select .ant-select-selection:hover {
    border-color: #1890ff;
  }
  &-select .ant-select-selection:focus {
    border-color: #1890ff;
  }
  &-select .ant-select-selection__placeholder {
    text-transform: uppercase;
  }

  &-select-option {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      background-color: #ebedee;
    }

    &:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #fff;
    }

    &:not(.ant-select-dropdown-menu-item-disabled):hover {
      background-color: #ebedee;
    }
  }

  &-label {
    width: fit-content;
    display: block;

    &_legality-documentation {
      width: 100%;
    }

    &_description-top {
      width: min-content;
      display: flex;
      flex-direction: column;
    }

    &_description-left {
      max-width: 422px;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }

    &_percentage {
      position: relative;
    }
  }

  &-label-description {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8e8e8e;

    margin-top: 0;
    margin-bottom: 0;

    display: block;
  }

  &-label-text {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    margin-top: 0;
    margin-bottom: 8px;
    margin-right: 14px;
  }

  &-checkbox {
    margin-right: 8px;
  }

  &-checkbox-text {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;

    margin-top: 0;
    margin-bottom: 0;
  }

  &-buttons-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 156px;
    @media (max-width: 700px) {
      margin-top: 10px;
      gap: 10px;
      flex-direction: column;
    }
  }

  &-save-button {
    min-height: 40px;
    min-width: 150px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #fff !important;
    text-transform: uppercase;

    background-color: rgba(24, 144, 255, 1) !important;
    border: 1px solid rgba(24, 144, 255, 1) !important;
    border-radius: 4px;
    box-sizing: border-box;
    opacity: 0.5;

    padding: 11px 16px;

    transition: 0.3s linear;

    &:hover,
    &:focus {
      color: #fff !important;
      background-color: rgb(24, 144, 255) !important;
      border: 1px solid rgba(24, 144, 255, 1) !important;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4) !important;
    }

    &:active {
      color: #fff;
      background-color: rgba(24, 144, 255, 1) !important;
      border: 1px solid rgba(24, 144, 255, 1) !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4) !important;
    }

    &_active {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.error-message {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ff0000;

  position: relative;

  &_description {
    top: -2px;
    right: 16px;
    display: block;

    margin-top: -12px;
    margin-left: auto;
  }

  &_text {
    top: -4px;
    right: 24px;
    display: block;

    margin-top: -12px;
    margin-left: auto;

    &_margin-min {
      margin-top: 7px;
    }
  }

  &_link {
    left: 0;
    bottom: -22px;
    position: absolute;
  }

  &_accrual-percentage {
    position: absolute;
    top: 54px;
    left: 250px;
  }

  &_bonus-price {
    position: absolute;
    top: 54px;
    left: 240px;
  }
}

.ant-upload-list-item .anticon-close {
  opacity: 1;
}

.ant-upload-list-text {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.ant-upload-list-item-done .anticon-close {
  font-size: 24px !important;
  top: -4px;
  right: -16px;
  color: #c4c4c4;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}

.ant-upload-list-item-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

input[type='checkbox'] {
  margin: 4px 8px 0 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff !important;
}
