.name {
  color: #5c5c5c;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding-right: 8px;
  width: 170px;
}

.content {
  display: flex;
  flex-direction: column;
}

.contentValue {
  display: flex;
  margin: 20px 0 20px 0;
}

.img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.localizedTextWrapper {
  display: grid;
  grid-template: 1fr / 5fr 19fr;
  align-items: center;

  h3 {
    margin: 0;
    color: #5c5c5c;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.customWrapper {
  label {
    grid-template: 1fr / auto 1fr;
    gap: 0;
    align-items: center;
    width: calc(50% - 10px);
    margin-right: 10px;
  }

  h3 {
    height: 100%;
    padding: 3px;
    line-height: 32px;
    background-color: #f2f2f2;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 2px 0 0 2px;
    border-right: none;
  }

  input {
    border-left: none !important;
    border-radius: 0 2px 2px 0 !important;
  }
}
