.user-data-modal {
  .ant-drawer-header {
    padding: 0;
    min-height: 68px;
    display: flex;
    align-items: center;
    &-title {
      display: flex;
      gap: 10px;
    }
  }
  .ant-drawer-title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    width: 50%;
  }
  .ant-drawer-close {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    left: 0;
    padding-left: 30px;
    margin: 0;
    i svg {
      width: 24px;
      height: 24px;
    }
  }

  &__wrapper {
    width: 100%;
    height: auto;
    padding: 20px 30px;
  }

  &__personal-data-wrapper {
    width: 100%;
    height: auto;
    padding: 20px 50px;
    background-color: #fff;
    border-radius: 9px;

    @media (max-width: 680px) {
      .user-data-modal {
        &__full-name {
          text-align: center;
        }
        &__personal-data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
        &__bonus-count {
          margin: 0;
        }
        &__personal-data__container {
          margin: 0;
        }
      }
    }
  }

  &__bonus-count {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #fff;

    background-color: #ff9500;
    border-radius: 30px;

    padding: 6px 10px;
    margin: 0 24px 0 0;
  }

  &__full-name {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 10px 0;
  }

  &__personal-data {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);

    &__container {
      width: auto;
      padding: 0 10px 0 0;
      margin: 0 24px 0 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        img {
          width: 18px;
          height: 18px;
        }
        p {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  &__events-data-wrapper {
    width: 100%;
    //height: 100%;
    margin: 15px 0 0 0;
    background-color: #fff;
    border-radius: 9px;
  }
}

.tab-title {
  width: 187px;
  text-align: center;
  margin-bottom: -8px;
}
