$size: 85px;
$header-color: #f5f5f5;

/* $header-color: #000; */
@keyframes size-down {
  from {
    min-width: 256px;
    width: 256px;
  }

  to {
    min-width: 80px;
    width: 80px;
  }
}

@keyframes scale-logo {
  from {
    height: 58px;
    width: 58px;
  }

  to {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

@keyframes rounded {
  from {
    padding: 0;
    background: transparent;
    border-radius: 0;
  }

  to {
    padding: 5px;
    background: white;
    border-radius: 50%;
  }
}

.header__display-container_language-container {
  margin-right: 10px;
  display: flex;
  gap: 5px;
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  &__text-language {
    font-family: TT Hoves;
    &:hover {
      cursor: pointer;
    }
    &__active {
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__logo-title {
    color: white;
    border-radius: 15px;
    border: 1.5px solid white;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
  }

  &__search {
    margin-right: 24px;
  }

  &__notifs {
    margin-right: 20px;
  }

  &__logo-container {
    padding-left: 6px;
    padding-right: 13px;
    min-width: 256px;

    a {
      width: 100%;
    }

    span {
      padding-right: 8.8px;
      padding-left: 27.2px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background: white;
        position: absolute;
        left: 9.1px;
        top: calc(50% - 6.5px);
        border-radius: 50%;
      }
    }

    img {
      height: 58px;
      width: 58px;
      object-fit: contain;
    }
  }

  &__info {
    width: 100%;
    padding: 0 20px;

    button {
      svg {
        height: 20px;
        width: 20px;
        color: #ffffff;
      }
    }
    .ant-btn {
      height: auto;
    }
    .ant-avatar-icon {
      display: flex;
      justify-content: center;
    }
  }

  &__logo-container--collapsed {
    animation: size-down 0s ease-out 0.2s;
    animation-fill-mode: forwards;
    display: flex;
    &.header__logo-container span {
      display: none;
    }

    &.header__logo-container img {
      animation: scale-logo 0s ease-out 0.3s, rounded 0.2s ease-out 0.3s;
      animation-fill-mode: forwards;
    }
  }
}

.anticon-menu-fold,
.anticon-menu-unfold {
  svg {
    height: 14px !important;
    width: 14px !important;
  }
}
