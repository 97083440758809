.upload {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  align-items: center;
  color: #5c5c5c;

  & > img {
    height: 213px;
    width: 166px;
    object-fit: cover;
    object-position: center center;

    @media screen and (max-width: 576px) {
      margin-bottom: 16px;
    }
  }

  &__loader {
    @media screen and (max-width: 514px) {
      max-width: 195px !important;
    }

    @media screen and (max-width: 350px) {
      max-width: 100% !important;
    }
  }
}

@media screen and (max-width: 820px) {
  .upload {
    & > img {
      height: 191px;
      width: 132px;
    }
  }
}

@media screen and (max-width: 350px) {
  .upload {
    grid-template: 1fr 1fr / 1fr;
  }
}
