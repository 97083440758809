$border-style: rgba(175, 175, 175, 1);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  list-style: none;
  text-decoration: none;
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
}
p,
input {
  margin: 0;
}
@mixin Flex($just, $align) {
  display: flex;
  justify-content: $just;
  align-items: $align;
  flex-wrap: wrap;
}
@mixin BlockSize($width, $height) {
  width: $width;
  height: $height;
}
@mixin FormField($margin) {
  display: block;
  border: 1px solid $border-style;
  border-radius: 5px;
  margin: $margin;
  padding-left: 10px;
  background-color: transparent;
}
@mixin paddingForText($top, $right, $bottom, $left) {
  padding: $top $right $bottom $left;
}
