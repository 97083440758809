.personalCoupon {
  :global {
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: var(--admin-theme_Grey_02);
    }
  }
}

.headerContainer {
  min-height: 78px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  background-color: #fff;
  @media (max-width: 510px) {
    flex-direction: column;
    padding-left: 0;
    gap: 10px;
  }
}
.iconLink {
  font-size: 24px;
}
.leftOutlinedIcon {
  font-size: 24px;
  margin-right: 34px;
  cursor: pointer;
  @media (max-width: 510px) {
    margin-right: 0;
  }
}
.headerTitle {
  font-size: 24px;
  line-height: 32px;
  font-style: normal;
  font-weight: 700 !important;
  color: var(--system_black);

  margin-bottom: 0;
}
.headerButton {
  margin-right: 24px;
  margin-left: auto;
  @media (max-width: 510px) {
    margin-right: 0;
    margin-left: 0;
  }
  span {
    color: #1890ff !important;
  }
  &:hover {
    span {
      color: rgb(73, 44, 97) !important;
    }
  }
}

.contentBlock {
  width: calc(100% - 48px);

  margin-top: 24px;
  margin-right: 24px;
  margin-bottom: 0;
  margin-left: 24px;
  padding: 30px;

  background-color: #fff;
  border-radius: 8px;
}
.contentBlockHeight {
  height: 200px;
}
.headerContentContainer {
  margin-bottom: 24px;
}
.contentTitle {
  font-size: 20px;
  line-height: 22px;
  font-style: normal;
  font-weight: 500;
  color: var(--system_black);
  display: inline-block;
}
.editOutlinedIcon {
  font-size: 16px;
  margin-left: 12px;
  cursor: pointer;
  opacity: 0.4;
  transition: 0.2s linear;

  &:hover {
    opacity: 1;
  }
}

.usersTable {
  margin-top: 22px;
}

.dropdown {
  display: block;
  margin-top: 48px;
}
