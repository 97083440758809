.card {
  background-color: #ffffff;
  height: 100%;
  padding: 24px 60px;
  border-radius: 8px;

  @media screen and (max-width: 576px) {
    padding: 16px;
    border-top: 1px solid #e8e8e8;
  }
}

.name {
  color: #5c5c5c;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding-right: 8px;
  width: 178px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    min-width: 100%;
  }
}

.item {
  margin-bottom: 16px;
  font-size: 16px !important;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.item_edit {
  margin-bottom: 24px;
}

.item_photoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title {
  font-weight: bold;
  letter-spacing: 0.15px;
  margin-top: 16px;
}

.select {
  font-size: 16px !important;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  text-transform: uppercase;
}

.value_en {
  color: lightgray;

  @media screen and (max-width: 576px) {
    display: flex;
  }
}

.photo {
  height: 213px;
  width: 166px;
  object-fit: cover;
  object-position: center center;

  @media screen and (max-width: 576px) {
    border-radius: 10px;
  }
}

.photo_modal {
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

.btnUpload {
  font-size: 14px !important;
  line-height: 22px;
  font-weight: bold;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.iconUpload {
  color: #c4c4c4;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.sizePhoto {
  color: #8e8e8e;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding: 8px 0 0 8px;
  width: 160px;
}

.photoBlock {
  display: flex;
  flex-direction: column;
}

.input {
  width: 230px;
  min-height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding-left: 12px;
}

.input_en {
  margin-left: 8px;
}

.block {
  background-color: #ebedee;
  border-radius: 4px;
  padding: 3px 8px;
  width: auto;
}

.paperclip {
  width: 14px;
  height: 14px;
  margin: 0 8px 3px 0;
}

.radio {
  width: 140px;
  margin-right: 13px;
}

.optionValue {
  font-size: 16px !important;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  width: 452px;
}

.option {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebedee;
}

.paperclip {
  width: 14px;
  height: 14px;
  margin: 0 8px 3px 0;
}

.btnEdit {
  color: #1890ff;
  font-size: 16px !important;
  line-height: 24px;
  font-weight: bold !important;
  letter-spacing: 0.5px;
  border: 2px solid #ebedee !important;
  border-radius: 4px !important;
  height: 48px;

  &:hover {
    border-color: rgb(73, 44, 97) !important;
    color: rgb(73, 44, 97) !important;
  }

  @media screen and (max-width: 576px) {
    height: 40px;
    font-size: 12px !important;
  }
}
