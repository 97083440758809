.loginForm {
  background-color: #ffffff;
  height: 100%;
  padding: 24px 60px;
  border-radius: 8px;

  @media screen and (max-width: 576px) {
    padding: 16px;
    padding-top: 75px;
  }
}

.name {
  align-self: center;
  color: #5c5c5c;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding-right: 8px;
  width: 178px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    min-width: 100%;
  }
}

.item {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}

.item_photoContainer {
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0.15px;
  margin: 0;
}

.select {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  text-transform: uppercase;
}

.value_en {
  color: lightgray;
}

.photo {
  height: 213px;
  width: 166px;
  object-fit: cover;
  object-position: center center;
}

.photo_modal {
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

.btnUpload {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.iconUpload {
  color: #c4c4c4;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.sizePhoto {
  color: #8e8e8e;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding: 8px 0 0 8px;
  width: 160px;
}

.photoBlock {
  display: flex;
  flex-direction: column;
}

.input {
  width: 230px;
  min-height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding-left: 12px;
}

.input_en {
  margin-left: 8px;
}

.block {
  background-color: #ebedee;
  border-radius: 4px;
  padding: 3px 8px;
  width: auto;
}

.paperclip {
  width: 14px;
  height: 14px;
  margin: 0 8px 3px 0;
}

.radio {
  width: 140px;
  margin-right: 13px;
}

// .serviceData__select {
//     width: 452px;
//     font-size: 16px;
//     line-height: 24px;
//     font-weight: bold;
//     letter-spacing: 0.5px;
//     color: #5c5c5c;
// }

.optionValue {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #000000;
  width: 452px;
}

// .serviceData__time {
//     color: #5c5c5c;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 16px;
//     letter-spacing: 0.25px;
//     width: 452px;
// }

.option {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebedee;
}

.btnSave {
  background-color: #1890ff;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  height: 48px;
  border-radius: 4px;
  border: none;
}

.transliterationInputGroup {
  h3 {
    color: #5c5c5c;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;

  }

  label {
    padding-right: 10px;
  }
}

@media screen and (max-width: 475px) {
  :global(.ant-legacy-form-item) {
    width: 100%;
  }
}
