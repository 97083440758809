.item {
  max-width: 775px;
}

.addUserInputWrapper {
  position: relative;
}

.textArea {
  width: 100%;
  min-height: 42px !important;

  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.25px;

  margin-bottom: 16px !important;
  padding-top: 8px;

  border: 1px solid var(--admin-theme_Grey_02);
  border-radius: 4px;

  &::placeholder {
    font-size: 14px;
    padding-top: 2px;
  }
}

.radioGroup {
  width: 100%;
  display: flex;
  align-items: center;
}

.wrongNumbersBlock {
  max-width: 340px;
  background-color: #FFF1F0;
  border: 1px solid #FFCCC7;
  border-radius: 2px;
  padding: 16px;
  margin-top: 16px;
}
.wrongNumbersBlockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrongNumbersBlockTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--admin-theme_Black);
  margin-top: 0;
  margin-bottom: 0;
}
.wrongNumbersBlockCross {
  font-size: 16px;
  color: var(--admin-theme_Grey_02);

  transform: rotate(45deg);
  transition: .3s linear;

  &:hover {
    color: var(--admin-theme_Black);
  }
}
.wrongNumbersBlockList {
  list-style: none;
  padding: 0;
  margin-top: 4px;
  margin-bottom: 0;
}
.wrongNumbersBlockItem {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--admin-theme_Grey_04);
  margin-top: 0;
  margin-bottom: 0;
}

.loadingPhonesIcon {
  position: absolute;
  top: 13px;
  right: -24px;
}

.table {
  margin-top: 20px;
}

.crossIcon {
  font-size: 16px;
  color: var(--admin-theme_Grey_02);

  transform: rotate(45deg);
  transition: .3s linear;

  &:hover {
    color: var(--admin-theme_Negative);
  }
}
