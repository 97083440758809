.card {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: flex;
  padding: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: black;
  position: relative;

  img,
  .placeholder {
    flex-shrink: 0;
    width: 100px;
    margin-right: 25px;
    object-fit: cover;
  }

  .placeholder {
    background: #ebedee;
  }

  .status {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    .name {
      margin-bottom: 5px;
      text-transform: capitalize;
      max-width: 80%;
      overflow: hidden;
      font-weight: 700;
      font-size: 24px;
      text-overflow: ellipsis;
    }

    .infoGridTop {
      margin-bottom: 10px;

      span {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
      }

      .itemGrey {
        opacity: 0.4;
      }
    }

    .infoGridBottom {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .block {
        display: flex;
        align-items: flex-start;
        width: 250px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .icon {
        margin-right: 8px;
        margin-top: 2px;
        width: 16px;
        height: 16px;
      }
    }

    .item {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 16px;
      min-height: 40px;
      position: relative;

      .btnContainer {
        & > :not(:last-child) {
          margin-right: 16px;
        }

        .buttonSpan {
          border: none;
          padding: 0;
          font-weight: normal;
          text-transform: none;
          text-shadow: none;
          background-color: transparent;
          color: #1890ff;
        }
      }

      .buttonPrint {
        display: flex;
        align-items: center;
        span {
          color: #000000d9 !important;
        }
        @media (max-width: 625px) {
          font-size: 10px;
          width: 95%;
        }
        .buttonPrintIcon {
          margin-right: 10px;
          @media (max-width: 690px) {
            display: none;
          }
        }
      }

      .link {
        display: flex;
        align-items: center;
        // position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        color: inherit;
        white-space: nowrap;

        @media (max-width: 690px) {
          span {
            display: none;
          }
        }

        div {
          margin-right: 5px;
          @media (max-width: 690px) {
            margin-right: 0;
            margin-left: 5px;
          }
        }

        @media screen and (max-width: 650px) {
          position: absolute;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.checkbox {
  min-width: 45px;

  &__btn {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 16px;
    width: 16px;
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
}

.cardMobile {
  display: none;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: black;

  .cardMobile__header {
    display: flex;
    margin-bottom: 12px;

    img,
    .placeholder {
      margin-right: 25px;
      width: 100px;
      height: 100px;
      max-width: 100%;
      flex-shrink: 0;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  &__status {
    display: inline-block;
    margin-bottom: 10px;
  }

  &__name {
    margin: 0;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 20px;

    @media (max-width: 420px) {
      font-size: 13px;
    }

    @media screen and (max-width: 350px) {
      font-size: 18px;
    }
  }

  .cardMobile__middle {
    margin-bottom: 12px;

    .cardMobile__info {
      &_top {
        margin-bottom: 10px;

        span {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
        }
      }

      &_bottom {
        display: flex;
      }

      &_content {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &_icon {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }

      &_text_grey {
        opacity: 0.4;
      }
    }
  }

  .cardMobile__footer {
    .cardMobile__btns {
      button {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 16px;

        @media screen and (max-width: 576px) {
          width: 46.6%;
          padding: 0;
          height: 44px;
        }
      }

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    display: block;
  }
}

@media (max-width: 1100px) {
  .card {
    .actions {
      .link {
        margin-bottom: 3px;
      }
    }
  }
}
