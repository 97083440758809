.dashboard {
  position: relative;
  margin-right: 61px;
  z-index: 9;
  &__container {
    pointer-events: none;
    opacity: 0;
    transform: translateY(30px);
    box-shadow: none;
    transition: all 0.15s ease;
    position: absolute;
    z-index: 1;
    right: -77px;
    top: calc(100% + 12px);
    background: white;
    border: 1px #dbdbdb solid;
    border-radius: 6px;
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: white;
      border: 1px #dbdbdb solid;
      transform: rotate(39deg) skew(-14deg);
      position: absolute;
      left: calc(50% - 10px);
      top: -10.6px;
      border-radius: 6px 0 0 0;
      border-right: none;
      border-bottom: none;
    }
  }
  &__container.is-opened {
    pointer-events: auto;
    display: block;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
    opacity: 1;
    transform: translateY(0);
  }
  &__user {
    padding: 16px 22px 10px 16px;
  }
  &__username {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
  }
  &__position {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #bfbfbf;
    margin-top: 2px;
  }
  &__action-list {
    padding: 0;
    margin: 0;
  }
  &__action-item {
    cursor: pointer;
    list-style: none;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.3px;
    padding: 11px 18px 14px 15px;
    border-top: 1px #bfbfbf solid;
    &,
    a {
      color: black;
      &:hover {
        color: var(--info);
      }
    }
  }
  &__action-item--primary {
    &,
    a {
      color: var(--info);
      &:hover {
        color: black;
      }
    }
  }
}
.pointer-ev {
  pointer-events: none;
}
