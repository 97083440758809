.InfoPopover {
  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    cursor: help;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    width: 462px;
    padding: 16px 8px 16px 16px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.85);
  }
}
