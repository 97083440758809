.wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.label {
  margin: 0;
  display: grid;
  gap: 4px;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  transform: translate(40%, -40%);

  &:hover {
    color: var(--danger);
    cursor: pointer;
  }
}

.itemTitle {
  flex-basis: max-content;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: var(--system_black);
}

.input {
  font-weight: 400;
  border: 1px solid var(--admin-theme_Grey_02) !important;
  border-radius: 4px;
}

.hint {
  font-size: 14px;
  color: var(--danger);
  display: block;
}

.directionRow {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.directionColumn {
  flex-direction: column;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.labelAbove {
  grid-template: auto 1fr / 1fr;

  h3 {
    margin: 0 0 4px 0;
  }
}

.labelLeft {
  grid-template: 1fr / auto 1fr;
  align-items: center;

  h3 {
    margin: 0 4px 0 0;
  }
}

@media (max-width: 820px) {
  .itemTitle {
    width: fit-content;
  }
}

@media (max-width: 576px) {
  .wrapper {
    flex-direction: column;
  }
}
