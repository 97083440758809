$button-background-color: #ebedee;
$warning-color: #ff4747;
$button-text-color: #5c5c5c;

.modal-warning {
  &__deactivate-button {
    min-height: 40px;
    min-width: 150px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    color: #ff4747 !important;
    text-transform: uppercase;

    background-color: $button-background-color !important;
    border: 1px solid $button-background-color !important;
    border-radius: 4px;
    box-sizing: border-box;

    margin-right: 6px;
    padding: 11px 16px;
    span {
      color: #ff4747 !important;
    }

    &:hover,
    &:focus {
      color: $warning-color !important;
      background-color: $button-background-color !important;
      border: 1px solid #ff4747 !important;
    }

    &:active {
      color: $warning-color !important;
      background-color: $button-background-color !important;
      border: 1px solid $warning-color !important;
    }
  }

  &__title-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);

    margin-top: 0;
    margin-bottom: 0;
  }

  &__close-circle {
    font-size: 24px;
    color: $warning-color;
    margin-right: 16px;
  }

  &__button-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__confirm-button {
    min-height: 40px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $warning-color;
    text-transform: uppercase;

    background-color: $button-background-color !important;
    border: 1px solid $button-background-color !important;
    border-radius: 4px;
    box-sizing: border-box;

    margin-right: 6px;
    padding: 13px 15px;

    &:hover,
    &:focus {
      color: $warning-color !important;
      background-color: $button-background-color !important;
      border: 1px solid $warning-color !important;
    }
  }

  &__cancel-button {
    height: 40px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 4px;
    color: $button-text-color !important;
    text-transform: uppercase;

    background-color: #fff !important;
    border: 1px solid $button-background-color !important;
    border-radius: 4px !important;
    box-sizing: border-box;

    padding: 19px 15px;
    box-shadow: none;
    &:hover,
    &:focus {
      color: $button-text-color !important;
      background-color: $button-background-color !important;
      border: 1px solid $button-background-color !important;
    }
  }
}
