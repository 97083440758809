:root {
    --admin-theme_Black: var(--admin-theme_Black-override, rgba(38,38,38,1));
    --admin-theme_Grey_00: var(--admin-theme_Grey_00-override, rgba(240,242,245,1));
    --admin-theme_Grey_02: var(--admin-theme_Grey_02-override, rgba(196,196,196,1));
    --admin-theme_Grey_03: var(--admin-theme_Grey_03-override, rgba(142,142,142,1));
    --admin-theme_Grey_04: var(--admin-theme_Grey_04-override, rgba(92,92,92,1));
    --admin-theme_Negative: var(--admin-theme_Negative-override, rgba(255,71,71,1));
    --admin-theme_Neutral: var(--admin-theme_Neutral-override, rgba(24,144,255,1));
    --admin-theme_Positive: var(--admin-theme_Positive-override, rgba(3,185,78,1));
    --admin-theme_Primary: var(--admin-theme_Primary-override, rgba(51,27,81,1));
}