.route-content_loyalty {
  &-close-button {
    height: 40px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 4px;
    color: #ff4747 !important;
    text-transform: uppercase;

    background-color: #ebedee !important;
    border: 1px solid #ebedee !important;
    border-radius: 4px !important;
    box-sizing: border-box;

    margin-right: 6px;
    padding: 19px 15px;

    &:hover,
    &:focus {
      color: #ff4747;
      background-color: #ebedee !important;
      border: 1px solid #ff4747 !important;
    }

    &:active {
      color: #ff4747 !important;
      background-color: #ebedee !important;
      border: 1px solid #ff4747 !important;
    }
  }
}
