.select {      
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #5c5c5c;
    text-transform: uppercase;

    @media screen and (max-width: 475px) {
        width: 100% !important;
    }
}
