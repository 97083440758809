.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba($color: #e5e5e5, $alpha: 0.7);

  &__logo {
    position: absolute;
    top: 40px;
    left: 40px;

    img {
      min-width: 120px;
      height: 30px;
    }

    span {
      opacity: 0.9;
      font-size: 28px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    &__text {
      font-weight: 700;
      color: var(--systemBlack);
      font-size: 30px;
    }
  }

  &__wrapper {
    border-radius: 10px;
    padding: 40px;
    width: 455px;
    background: #fff;
    box-shadow: 0px 7px 14px 2px rgba(34, 60, 80, 0.2);
  }

  &__container {
    width: 360px;
    height: 390px;
    background-color: #fff;
    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    font-family: Roboto-bold;
    font-weight: 700;
    font-size: 36px;
    color: #333;
  }

  &__data {
    display: flex;
    flex-direction: column;
  }

  &__label {
    position: relative;
    margin-bottom: 30px;

    span {
      display: inline-block;
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__input {
    padding-bottom: 8px;
    width: 100%;
    border-bottom: 2px solid rgba($color: #323232, $alpha: 0.5);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    transition: border-color 0.3s linear;

    &::placeholder {
      letter-spacing: normal;
    }

    &:focus,
    &:hover {
      border-color: var(--secondary);
    }
  }

  &__button {
    margin: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 10px;
    border: none;
    background: var(--primary);

    &_password {
      position: absolute;
      right: 4px;
      top: 31px;
      padding: 0;
      width: 24px;
      height: 24px;
      background: none;
      border: none;

      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background: none !important;
        border-color: unset !important;
      }

    }

    &--dark {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #282828;
      color: var(--primary);

      &:hover {
        background: #1a1a1a;
        color: var(--primary);
      }

      &:focus {
        color: var(--primary);
      }
    }
  }

  &__reg {
    margin-top: 24px;
  }

  &__form {
    width: 300px;
    margin-top: 37px;

    .ant-btn {
      margin-top: 10px;
    }
  }

  .input {
    width: 100%;
    margin-bottom: 12px;
    padding: 13px 16px;
    box-sizing: border-box;
    height: 48px;
  }

  &__link {
    display: block;
    width: 100%;
    text-align: center;
    background: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-top: 12px;

    &:hover {
      color: var(--primary);
      border: none;
    }

    &:active {
      background: none;
    }
  }
}

.form-controller {
  width: 340px;
  overflow: hidden;
  position: relative;
  height: 340px;

  &__wrap {
    display: flex;
    position: absolute;
    width: 1020px;
    height: max-content;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    transform: none;
  }

  &__wrap form {
    width: 340px;
    padding: 0 10px;
  }
}

.form-controller__wrap.to-reset {
  transform: translateX(-340px);
}

.form-controller__wrap.to-feedback {
  transform: translateX(-680px);
}

.reset-pass__form p {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  text-align: center;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 37px;
  margin-bottom: 13px;
}

.form__feedback {
  width: 340px;

  p {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    text-align: center;
    margin-top: 37px;
    margin-bottom: 13px;
  }
}

.login__link svg {
  width: 22px;
  height: 12px;
  object-fit: contain;
  margin-bottom: -3px;
  transform: rotate(90deg);
}

.login__link:hover path {
  stroke: var(--primary);
}

@media (max-width: 550px) {
  .login__wrapper {
    width: 350px;
  }

  .login__title {
    font-size: 28px;
  }
}
