.configuration-list {

  &__block {
    position: relative;
    background-color: #FAFAFA;
    border-radius: 8px;

    margin-top: 15px;
    margin-bottom: 20px;
    padding: 16px 16px 16px 24px;
  }

  &__table {
    margin-top: -16px;
  }

  &__table-column {
    background-color: #FAFAFA !important;
    border: none !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

}
