.category {
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 576px) {
    justify-content: flex-start;
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 1250px) {
      display: flex;
      justify-content: center;
      margin: 0;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
      margin: 0 auto;
    }

    @media screen and (max-width: 576px) {
      justify-content: flex-start;
      margin: 0;
    }
  }

  &__listBtn {
    background: #ffffff;
    min-height: 30px;
    padding: 0 32px;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #c4c4c4 !important;
    color: #000000d9 !important;
    font-weight: 400 !important;
    &:hover {
      border: 1px solid #c4c4c4 !important;
      color: #000000d9 !important;
    }
    @media screen and (max-width: 1250px) {
      min-width: 250px;
      margin-bottom: 3px;
      border-radius: 4px;
    }

    @media screen and (max-width: 576px) {
      padding: 4px 16px;
      min-width: 100px;
    }
  }

  &__listBtn_active {
    background: #e6f7ff;
    border: 1px solid #1890ff !important;
    color: #1890ff !important;
    font-weight: bold !important;
    &:hover {
      border: 1px solid #1890ff !important;
      color: #1890ff !important;
    }
  }
}

.category__listItem:not(:last-child) {
  @media screen and (max-width: 1250px) {
    margin-right: 8px;
  }
}

.category__listItem:last-of-type {
  .category__listBtn {
    border-radius: 0 4px 4px 0;
    @media screen and (max-width: 1250px) {
      border-radius: 4px;
    }
  }
}

.category__listItem:first-of-type {
  .category__listBtn {
    border-radius: 4px 0 0 4px;
    @media screen and (max-width: 1250px) {
      border-radius: 4px;
    }
  }
}
