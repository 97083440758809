.segments__segment {
  &-buttons {
    width: 100%;
    min-height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ebedee !important;
    margin-top: auto;
  }

  &-button-save {
    border: 0;
    box-shadow: none;
    border-color: rgb(73, 44, 97) !important;
    span {
      color: #ebedee !important;
    }
  }

  &-button-cancel {
    background-color: #ebedee !important;
    color: #ff4747 !important;
    border-color: #ebedee !important;
    padding: 8px 16px;
    span {
      color: #ff4747 !important;
    }
    &:hover {
      border-color: #ff4747 !important;
      background: #ece9e0 !important;
    }
  }
}
