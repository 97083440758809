.closeIcon {
  font-size: 26px;
  color: var(--admin-theme_Grey_02);

  transform: rotate(45deg);
  transition: 0.3s linear;

  &:hover {
    color: var(--admin-theme_Black);
  }
}

.modalTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  margin-top: 0;
  margin-bottom: 0;
}

.title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--system_black);

  margin-top: 24px;
  margin-bottom: 16px;
}

.item {
  display: inline-block;
}

.numberInput {
  width: 74px;
  border-radius: 4px;
  min-height: 40px;
  margin-right: 8px;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover,
  &:focus {
    -moz-appearance: number-input;
  }
}

.modalFooter {
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding-top: 16px;
  margin-top: 32px;
}

.modalSaveButton {
  min-width: 170px;
  margin-left: auto;
  border-radius: 4px;
  box-shadow: none;
  span {
    font-weight: 700 !important;
  }
}
