.filter { 
  display: flex;
  justify-content: flex-end;

  .searchInput {
    width: 358px;    
    @media screen and (max-width: 930px) {
      width: 250px;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    margin-bottom: 14px;
    width: 100%;
    justify-content: flex-start;
    order: -1;
  }
}

