.status {
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  border: 1px solid currentColor;
  border-radius: 4px;

  @media screen and (max-width: 576px) {
    font-size: 16px;
  }

  @media screen and (max-width: 380px) {
    font-size: 14px;
  }
}
