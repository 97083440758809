.userRegistration {
  :global {
    .ant-upload-list-item-card-actions-btn {
      background-color: transparent !important;
    }
  }
}

.modalTitleBlock {
  max-width: inherit;
  width: calc(80% - 16px);
  min-height: 68px;
  position: fixed;
  top: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--system_white);
  border-bottom: 1px solid var(--admin-theme_Grey_00);
  z-index: 1;

  padding-left: 20px;
  padding-right: 24px;
}

.modalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--admin-theme_Black);
  margin-left: 20px;
  margin-top: 10px;
}

.modalSaveButton {
  min-width: 237px;
  margin-left: auto;
  box-shadow: none;

  &:disabled {
    cursor: not-allowed !important;
  }
}

.modalCloseIcon {
  font-size: 16px;
  color: var(--admin-theme_Grey_02);
  transition: 0.3s linear;

  &:hover {
    color: var(--admin-theme_Black);
  }
}

.modalBody {
  width: auto;
  min-height: 100%;
  background-color: var(--system_white);
  border-radius: 9px;

  margin-top: 88px;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-left: 30px;

  padding-top: 24px;
  padding-right: 32px;
  padding-bottom: 72px;
  padding-left: 32px;
}

.formTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: left;

  margin-top: 48px;
  margin-bottom: 36px;

  &:first-child {
    margin-top: 0;
  }
}

.itemTitle {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: var(--system_black);

  margin-top: 24px;
  margin-bottom: 12px;
  margin-left: 2px;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  max-width: 775px;
}

.localizedTextItem {
  max-width: 775px;
  margin-bottom: 24px !important;

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

.localizedGroupWrapper {
  width: 100%;

  input {
    background-color: #fff;
  }

  label {
    width: 45% !important;
  }
}

.select {
  min-height: 40px;
  border-radius: 4px;
}

.input {
  width: 100%;
  border: 1px solid var(--admin-theme_Grey_02) !important;
  border-radius: 4px;
}

.input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    -moz-appearance: number-input;
  }
}

.prompt {
  display: inline-block;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--admin-theme_Grey_03);

  margin-top: 4px;
}

.inputMask {
  width: 100%;
  height: 40px;

  font-size: 16px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.85);

  border: 1px solid var(--admin-theme_Grey_02);
  border-radius: 4px;
  padding: 6.5px 11px;
}

@media (max-width: 820px) {
  .modalTitleBlock {
    width: 100%;
    position: inherit;
  }
  .modalBody {
    margin-top: 10px;
  }
}

@media (max-width: 560px) {
  .modalTitleBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 24px 10px 20px;

    h2,
    button {
      margin: 0;
      text-align: center;
    }
  }
}
