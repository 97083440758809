.route-content_loyalty {
  &-exclamation-circle {
    font-size: 24px;
    color: #F8A608;

    margin-top: -4px;
    margin-left: -18px;
    margin-right: 9px;
  }

  &-ok-button {
    height: 40px;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 4px;
    color: #fff;
    text-transform: uppercase;

    background-color: #1890FF;
    border: 1px solid #1890FF;
    border-radius: 4px;
    box-sizing: border-box;

    padding: 19px 15px;

    &:hover, &:focus {
      color: #fff;
      background-color: rgba(24, 144, 255, .8);
      border: 1px solid #1890FF;
    }

    &:active {
      color: #fff;
      background-color: #1890FF;
      border: 1px solid #1890FF;
    }
  }
}