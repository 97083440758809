.container {    
    margin: 0;

    @media screen and (max-width: 375px) {
        margin-bottom: 10px;
    } 

    @media screen and (max-width: 576px) {
        width: 100%;
    }   
}

.select {
    width: 300px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #5C5C5C;

    span[aria-live="polite"]{
        margin: 0;
    }

    @media screen and (max-width: 1325px) {
        width: 190px;
    }

    @media screen and (max-width: 750px) {
        width: 240px;
    }

    @media screen and (max-width: 576px) {
        width: 100%;
    }   
}
