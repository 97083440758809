.loyalty-table {
  &__transaction-value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #323232;
    @media (max-width: 560px) {
      font-size: 8px;
    }
    &_canceled {
      color: rgba(50, 50, 50, 0.5);
    }
  }

  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #8e8e8e;
    @media (max-width: 560px) {
      font-size: 6px;
    }
  }

  &__comment {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #8e8e8e;
    @media (max-width: 560px) {
      font-size: 8px;
    }
  }
}
