.AccessEntrances {
  height: 100%;
  li {
    height: 60px !important;
  }
  &__content {
    // If the "Stadiums" tabs are enabled, then you need to remove the top space
    height: calc(100% - 90px);
    margin: 24px;
    background-color: #fff;
    display: flex;
    position: relative;
    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;
  }

  &__addButton {
    border: 2px solid #ebedee !important;
    border-radius: 4px !important;
    padding: 0px 16px 0 20px;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    span:last-child {
      color: #1890ff !important;
    }
    &:hover {
      border-color: #1890ff !important;
      color: #1890ff !important;
    }
  }

  &__addButton-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__addButton-icon {
    color: #c4c4c4 !important;
    font-size: 20px;
  }

  &__title {
    margin: 0;

    font-size: 36px;
    line-height: 32px;
    box-sizing: border-box;
    letter-spacing: 0.5px;
  }

  &__left-container {
    max-width: 382px;
    width: 100%;
    overflow-y: auto;
    color: #000;
    z-index: 1;
    @media (max-width: 450px) {
      ul {
        display: flex;
        li {
          width: 70px !important;
        }
      }
    }
  }

  &__button-delete {
    width: 139px;
    min-height: 40px;
    background-color: #ebedee;
    border: 1px solid #ebedee;

    box-shadow: none;
    color: #ff4747;

    &:hover {
      color: #ff4747;
      background-color: #ebedee;
      border: 1px solid #fff;
    }
    &:focus {
      color: #ff4747;
      background-color: #ebedee;
      border: 1px solid #fff;
    }
  }

  &__button {
    width: 202px;
    min-height: 40px;
    background-color: #1890ff;
    border: 1px solid #1890ff;
    box-shadow: none;

    &:hover {
      background-color: #1890ff;
      border: 1px solid #fff;
    }
    &:focus {
      background-color: #1890ff;
      border: 1px solid #fff;
    }
    &_hidden {
      display: none;
    }
  }

  &__right-container-content {
    max-height: 80vh;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    &-block {
      display: none;
    }
    @media (max-width: 450px) {
      &-block {
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        span {
          padding-top: 5px;
          padding-right: 20px;
        }
      }
    }
  }

  &__right-container-title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    line-height: 100.7%;
    margin: 0 0 24px;
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px 0 0 32px;
    z-index: 2;
    width: 100%;
    color: #000;
  }
}

.ant-tabs-tab {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}
