.modal {
  width: calc(100% - 330px) !important;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  height: 100%;

  &_modal {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    width: 100% !important;
    max-width: 100vw;
  }
}

@media (max-width: 800px) {
  .modal {
    :global(.ant-modal-body) {
      padding: 0 !important;
    }
  }
}
