.subtable-title {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__first-row {
    display: flex;
    align-items: flex-start !important;
  }
}

@media (max-width: 910px) {
  .block-column {
    text-align: center !important;
  }
  .ant-table-layout-fixed {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .subtable {
    &-wrapper {
      padding-top: 0 !important;
    }
    &-title {
      padding-top: 0 !important;
    }
  }

  .ant-table {
    &-title {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-row {
      .ant-table-cell {
        text-align: center !important;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          overflow-wrap: anywhere;
        }
      }
    }
    &-cell {
      padding: 10px !important;
      @media (max-width: 560px) {
        font-size: 8px;
        padding: 3px !important;
      }

      @media (max-width: 440px) {
        font-size: 6px;
      }
    }
    &-expanded-row {
      .subtable-title {
        display: flex;
        flex-direction: row;
        gap: 10px;
        &__item {
          padding: 0;
        }
        &__first-row {
          justify-content: center;
          text-align: center;
        }
        &__text_main {
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
      .ant-table-container {
        .ant-table-cell {
          padding: 3px;
          text-align: center !important;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .subtable-title {
    &__item {
      height: 80px;
    }
    &__text_main {
      align-items: center;
      height: 35px;
    }
  }
}
