.segments {
  height: 100%;

  &__header-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    @media (max-width: 640px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      h1 {
        padding: 0 !important;
      }
      button {
        margin: 0;
      }
    }
  }

  &__header-title {
    margin-bottom: 0;
  }

  &__header-button {
    margin-right: 24px;
    span {
      color: #1890ff !important;
    }
    &:hover {
      color: rgb(73, 44, 97) !important;
      span {
        color: rgb(73, 44, 97) !important;
      }
    }
  }

  &__header-button-icon {
    width: 16px;
    color: #c4c4c4;
  }

  &__container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 24px 24px 0 24px;
    justify-content: center;

    @media (max-width: 760px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  &__container-left {
    max-width: 382px;
    width: 100%;
    overflow-y: auto;
    background-color: #fff;
  }

  &__container-right {
    width: 100%;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  &__segment-buttons {
    @media (max-width: 640px) {
      flex-direction: column;
      gap: 10px;
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
  }
}

.segments__segment-placeholder-wrapper {
  button {
    border-radius: 4px !important;
    border: 2px solid rgba(217, 217, 217, 0.456);
    span {
      color: #1890ff !important;
    }
    &:hover {
      border: 2px solid #1890ff;
    }
  }
}

@media (max-width: 1370px) {
  .segments__container-left {
    max-width: 370px;
  }
}

@media (max-width: 380px) {
  .ant-input-suffix {
    display: none !important;
  }
}
