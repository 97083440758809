.user-list {
  &__header-container {
    min-height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--system_white);
    h1 {
      font-weight: 500;
    }
    button {
      font-weight: 700;
      border: 1px solid rgb(217, 217, 217);
      &:hover {
        border-color: rgb(73, 44, 97);
        color: rgb(73, 44, 97);
      }
    }
  }

  &__header-title {
    padding: 0 0 0 33px !important;
    margin: 0;
  }

  &__header-button {
    margin-right: 24px;
  }

  &__table-row {
    &:hover {
      cursor: pointer;
    }
  }
}

.ant-pagination {
  max-width: 95%;
  &:hover {
    button {
      background-color: transparent !important;
    }
  }
  &-item {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    &:hover {
      background-color: transparent !important;
    }
    &-active {
      border-color: #371854 !important;
    }
    &-link {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  &-disabled {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  &-next {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  &-prev {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.ant-tabs-tab {
  font-size: 12px !important;
}

.ant-table-cell {
  font-weight: 500 !important;
}

.ant-table-content table {
  table-layout: fixed !important;
  colgroup {
    display: flex;
  }
}

@media (max-width: 420px) {
  .ant-radio-group {
    margin-bottom: 10px;
  }
}

@media (max-width: 910px) {
  .ant-pagination {
    justify-content: center !important;
  }
  .ant-table {
    &-wrapper {
      margin: 0 !important;
      div {
        font-size: 10px;
      }
    }
    &-cell {
      text-align: center !important;
    }
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 530px) {
  .ant-table-wrapper div {
    font-size: 8px;
  }
}

@media (max-width: 452px) {
  .ant-table-wrapper div {
    font-size: 6px;
  }
}
