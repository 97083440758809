.promocodes-modal {
  .ant-modal-header {
    text-align: center;
    margin: 0px;
    .ant-modal-title {
      font-size: 16px !important;
      font-family: Roboto !important;
      font-weight: bold !important;
    }
  }

  .ant-modal-content {
    .ant-modal-footer {
      padding-right: 24px !important;
    }
  }

  .ant-modal-close {
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    &-x {
      opacity: 0.6;
    }
  }

  .ant-modal-body {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }

  &__body {
    width: 100%;
    height: 380px;
    overflow-x: hidden;
  }

  &__values-wrapper {
    height: 71px;
    * {
      display: flex;
      align-items: center;
    }
    &__odd-elem {
      background-color: #fafafa;
    }

    span {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0 5px;
    }
  }

  &__values {
    width: 85%;
    height: 100%;
    font-family: Roboto;
    font-weight: bold;
  }

  &__remove-btn {
    width: 100%;
    height: 100%;
    i {
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      border: 1px solid var(--default-border);
    }
  }

  &__add-codes-input,
  &__prefix-input {
    width: 160px;
    margin: 0 15px 0 0;
  }

  &__generate-btn {
    background-color: var(--clear-blue);
    color: var(--white);
    font-size: 13px;
    &:hover {
      border: 1px solid #000 !important;
    }
  }
}
